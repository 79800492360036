import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  FormGroup,
  Checkbox,
  Button,
} from '@mui/material';
import axios from 'axios';
import { useState } from 'react';
import { apiBaseUrl } from '../../services/api';
import { useSelector } from 'react-redux';

interface GoogleDirectDrawerProps {
  termsList: { id: number; text: string; matchtype: string }[];
  termType: string;
  campaignLock: string;
  adGroupLock: string;
  removeTerm: (termid: number) => void;
  uncheckAllTerms: () => void;
  setAnalyticsLoading?: any;
}

const GoogleDirectDrawer: React.FC<GoogleDirectDrawerProps> = ({
  termsList,
  termType,
  campaignLock,
  adGroupLock,
  removeTerm,
  uncheckAllTerms,
  //setTermsList,
  setAnalyticsLoading,
}) => {
  const [newMatchType, setNewMatchType] = useState<
    'BROAD' | 'EXACT' | 'PHRASE'
  >('BROAD');

  const state = useSelector((state: any) => state);

  const handleMatchTypeChange = async () => {
    setAnalyticsLoading(true);
    const devToken = '',
      accountId = state.brand.brand.googleAdAccountId,
      customerId = state.brand.brand.googleCustomerIds
        ? state.brand.brand.googleCustomerIds[0]
        : '',
      adGroup = {
        resource_name: 'customers/5721170419/adGroups/159560108141',
        id: 159560108141,
        name: 'Competitor',
      },
      matchType = newMatchType,
      keyword = {
        match_type: `PHRASE`,
        text: 'planet fitness franchise',
      };

    axios
      .post(`${apiBaseUrl}/report/brand/campaign/google/keywords`, {
        customerId,
        accountId,
        devToken,
        adGroup,
        matchType,
        keyword,
      })
      .then((response) => {
        console.log(`Success: report/brand/campaign/google/keywords}`);
        console.log(response);
      })
      .catch((error) => {
        console.log(`Error: report/brand/campaign/google/keywords}`);
        console.log(error);
      })
      .finally(() => {
        setAnalyticsLoading(false);
      });
  };

  const changeMatchType = (target: 'BROAD' | 'EXACT' | 'PHRASE') => {
    setNewMatchType(target);
  };

  return (
    <Box
      bgcolor={`#eee`}
      borderTop={`1px solid #ccc`}
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        minHeight: '100px',
        maxHeight: '30vh',
        zIndex: 1000,
        paddingLeft: `240px`,
      }}
      overflow={`auto`}
      hidden={termsList.length <= 0}
    >
      <Box padding={`10px`}>
        <Box
          display={`flex`}
          flexDirection={`row`}
          justifyContent={`space-between`}
          mb={`10px`}
        >
          <Typography color={`#096f4d`} fontWeight={600}>
            <Box component="span" sx={{ fontWeight: 800 }}>
              {termType}
            </Box>{' '}
            [{termsList.length}]
          </Typography>
          <Chip
            style={{ display: `none` }}
            label={`Uncheck all items`}
            color="primary"
            onClick={uncheckAllTerms}
          />
        </Box>

        <Box
          borderRadius={`20px`}
          bgcolor={`#fff`}
          paddingX={`20px`}
          paddingY={`5px`}
          display={`flex`}
          flexWrap={`wrap`}
          gap={`5px`}
          maxHeight={`90px`}
          overflow={`auto`}
        >
          {termsList.map((k, i) => {
            return (
              <Chip
                key={i}
                label={`${k.text}[${k.matchtype}]`}
                // onDelete={() => {
                //   removeTerm(k.id);
                // }}
              />
            );
          })}
        </Box>

        <Box marginTop={`10px`}>
          {termType === 'Keywords' ? (
            <>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  <Typography color={`#096f4d`} fontWeight={600}>
                    Change Match Type
                  </Typography>
                </FormLabel>
                <RadioGroup row>
                  <FormControlLabel
                    value="Broad"
                    control={<Radio checked={newMatchType === 'BROAD'} />}
                    label="Broad"
                    onChange={() => {
                      changeMatchType('BROAD');
                    }}
                  />
                  <FormControlLabel
                    value="Exact"
                    control={<Radio checked={newMatchType === 'EXACT'} />}
                    label="Exact"
                    onChange={() => {
                      changeMatchType('EXACT');
                    }}
                  />
                  <FormControlLabel
                    value="Phrase"
                    control={<Radio checked={newMatchType === 'PHRASE'} />}
                    label="Phrase"
                    onChange={() => {
                      changeMatchType('PHRASE');
                    }}
                  />
                </RadioGroup>
              </FormControl>

              <div
                style={{ display: `flex`, gap: `10px`, alignItems: 'center' }}
              >
                <Button onClick={handleMatchTypeChange}>
                  <Chip label={`Save Changes`} color="primary" />
                </Button>

                <FormGroup row>
                  <FormControlLabel
                    control={<Checkbox disabled />}
                    label="Save to Assests"
                  />
                  <FormControlLabel
                    control={<Checkbox disabled />}
                    label="Save to Templates"
                  />
                </FormGroup>
              </div>
            </>
          ) : (
            <>
              <Box display={`flex`} gap={`1.2rem`} marginTop={`2rem`}>
                <Chip label={`Add as Keyword`} color="primary" />
                <Chip label={`Add as Negative Keyword`} color="primary" />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default GoogleDirectDrawer;
