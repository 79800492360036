import {
  Box,
  Typography,
  Grid,
  Chip,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { useEffect, useState } from 'react';
import GoogleDirectCampaignTable from './GoogleDirectCampaignTable';
import { LicenseInfo } from '@mui/x-license';
interface GoogleDirectCampaignMetricsProps {
  directData: any;
  brand: string;
  fromDate: string;
  toDate: string;
  allowCheckboxes: boolean;
  setAnalyticsLoading?: any;
}
const GoogleDirectCampaignMetrics: React.FC<
  GoogleDirectCampaignMetricsProps
> = ({
  directData,
  brand,
  fromDate,
  toDate,
  allowCheckboxes = false,
  setAnalyticsLoading,
}) => {
  const [campaigns, setCampaigns] = useState<any[]>(null);
  const [adGroups, setAdGroups] = useState<any[]>(null);
  const [keywords, setKeywords] = useState<any[]>(null);
  const [searchTerms, setSearchTerms] = useState<any[]>(null);
  const [locations, setLocations] = useState<any[]>(null);
  const setMetrics = (data: any) => {
    let metrics = {
      clicks: 0,
      impressions: 0,
      cost: 0,
      cpm: 0,
      cpc: 0,
      conversions: 0,
    };
    if (data) {
      data.forEach((d: any) => {
        metrics.clicks += d.metrics.clicks;
        metrics.impressions += d.metrics.impressions;
        metrics.cost += d.metrics.cost_micros;
        metrics.cpm += d.metrics.average_cpm;
        metrics.cpc += d.metrics.average_cpc;
        metrics.conversions += d.metrics.conversions;
      });
    }
    return metrics;
  };
  const setTableVariables = async (data: any) => {
    if (data) {
      setCampaigns(
        data[`campaign`].map((c: any, i: number) => {
          return {
            raw: { ...c },
            id: `c-${i + 1}`,
            name: c.campaign.name,
            ...setMetrics([c]),
          };
        }),
      );
      setAdGroups(
        data[`ad_group`].map((a: any, i: number) => {
          return {
            raw: { ...a },
            id: `ag-${i + 1}`,
            name: a.ad_group.name,
            ...setMetrics([a]),
          };
        }),
      );
      setKeywords(
        data[`keyword`]
          .filter((k: any) => !k.ad_group_criterion.negative)
          .map((k: any, i: number) => {
            return {
              raw: { ...k },
              id: `k-${i + 1}`,
              name: k.ad_group_criterion.keyword.text,
              ...setMetrics([k]),
            };
          }),
      );

      setSearchTerms(
        data[`search_term`].map((st: any, i: number) => {
          return {
            raw: { ...st },
            id: `st-${i + 1}`,
            name: st.search_term_view.search_term,
            ...setMetrics([st]),
          };
        }),
      );
    }
  };
  useEffect(() => {
    if (directData) {
      setTableVariables(directData);
    }
  }, [directData]);
  return (
    <Box
      position={`relative`}
      marginTop={`20px`}
      sx={{
        '& .green': {
          backgroundColor: 'green',
          fontWeight: 'bold',
        },
        '& .yellow': {
          backgroundColor: 'yellow',
          fontWeight: 'bold',
        },
        '& .red': {
          backgroundColor: 'red',
          fontWeight: 'bold',
        },
        '& .primary': {
          backgroundColor: '#1976d2',
          fontWeight: 'bold',
        },
      }}
    >
      <Typography variant="h5" fontWeight={800} textAlign={`center`}>
        {brand} Campaigns
      </Typography>

      <Typography fontWeight={400} textAlign={`center`}>
        {fromDate} - {toDate}
      </Typography>

      <GoogleDirectCampaignTable
        setAnalyticsLoading={setAnalyticsLoading}
        allowCheckboxes={allowCheckboxes}
        campaigns={campaigns}
        campaignList={directData ? directData[`campaignList`] : []}
        ad_groups={
          directData
            ? directData[`combinedAdGroups`]
              ? directData[`combinedAdGroups`]
              : []
            : []
        }
        keywords={
          directData ? (directData[`keyword`] ? directData[`keyword`] : []) : []
        }
        search_terms={
          directData
            ? directData[`search_term`]
              ? directData[`search_term`]
              : []
            : []
        }
        loading={directData ? false : true}
      />
    </Box>
  );
};

export default GoogleDirectCampaignMetrics;
